var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1450px","persistent":"","retain-focus":false,"scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('new')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("FORMS.position"))+" ")]):_vm._e()]}}]),model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.statuses.career)?_c('v-select',{attrs:{"items":_vm.statuses.career.career,"label":_vm.$t('FORMS.status'),"item-text":"value","item-value":"key"},model:{value:(_vm.formModel.status),callback:function ($$v) {_vm.$set(_vm.formModel, "status", $$v)},expression:"formModel.status"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":_vm.$t('FORM_INPUT_NAMES.name'),"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.name']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.name'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].name),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "name", $$v)},expression:"formModel.translations[selectedLocale.lang].name"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'textarea',
                label: _vm.$t('FORMS.intro'),
              },"customFieldName":"intro","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .intro},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"backgroundImage","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .backgroundImage || '',"config":{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'FORM_INPUT_NAMES.description',
                    type: 'text',
                  } ],
                selectButtonText: _vm.$t(
                  'FORM_INPUT_NAMES.select_background_image'
                ),
                initialColumnClass:
                  'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('ckeditor',{attrs:{"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'],"config":_vm.editorConfig1},on:{"input":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].text),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "text", $$v)},expression:"formModel.translations[selectedLocale.lang].text"}}):_vm._e(),(_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'])?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.messages["translations." + _vm.selectedLocale.lang + ".text"])+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"serviceImage","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .serviceImage || '',"config":{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'FORM_INPUT_NAMES.description',
                    type: 'text',
                  } ],
                selectButtonText: _vm.$t('FORM_INPUT_NAMES.select_image'),
                initialColumnClass:
                  'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("FORMS.highlighted_text"))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'textarea',
                label: _vm.$t('FORMS.intro'),
              },"customFieldName":"highlightedIntro","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .highlightedIntro},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'ckeditor',
                label: 'Text',
                height: '550',
                editorConfig: _vm.editorConfig,
              },"customFieldName":"highlightedText","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .highlightedText},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("FORMS.requirements"))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('ckeditor',{attrs:{"error-messages":_vm.messages[
                  'translations.' + _vm.selectedLocale.lang + '.requirements'
                ],"config":_vm.editorConfig2},on:{"input":function($event){_vm.messages[
                  'translations.' + _vm.selectedLocale.lang + '.requirements'
                ] = ''}},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].requirements
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "requirements", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].requirements\n              "}}):_vm._e(),(
                _vm.messages[
                  'translations.' + _vm.selectedLocale.lang + '.requirements'
                ]
              )?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.messages[ "translations." + _vm.selectedLocale.lang + ".requirements" ])+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('ckeditor',{attrs:{"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.tasks'],"config":_vm.editorConfig3},on:{"input":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.tasks'] =
                  ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].tasks),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "tasks", $$v)},expression:"formModel.translations[selectedLocale.lang].tasks"}}):_vm._e(),(
                _vm.messages['translations.' + _vm.selectedLocale.lang + '.tasks']
              )?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.messages["translations." + _vm.selectedLocale.lang + ".tasks"])+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('ckeditor',{attrs:{"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.benefits'],"config":_vm.editorConfig4},on:{"input":function($event){_vm.messages[
                  'translations.' + _vm.selectedLocale.lang + '.benefits'
                ] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].benefits),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "benefits", $$v)},expression:"formModel.translations[selectedLocale.lang].benefits"}}):_vm._e(),(
                _vm.messages['translations.' + _vm.selectedLocale.lang + '.benefits']
              )?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.messages["translations." + _vm.selectedLocale.lang + ".benefits"])+" ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("CONTACT_FORM.contact_info"))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'textarea',
                label: _vm.$t('FORMS.name'),
              },"customFieldName":"formName","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .formName},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'textarea',
                label: _vm.$t('FORMS.intro'),
              },"customFieldName":"formIntro","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .formIntro},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"formImage","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .formImage || '',"config":{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'FORM_INPUT_NAMES.description',
                    type: 'text',
                  } ],
                selectButtonText: _vm.$t('FORM_INPUT_NAMES.select_image'),
                initialColumnClass:
                  'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1),_c('SnackBarInfoComponent',{attrs:{"snackbarInfo":_vm.snackbarInfo}})],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }